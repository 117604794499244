import React from 'react';
import { graphql } from 'gatsby';

// Components
import SeoDetails from '../components/common/seoDetails';
import HeroImage from '../components/common/sections/heroImage';
import Gallery from '../components/common/sections/gallery';

const GalleryPage = ({ data }) => {
  const { seoContent, contentSections } = data.contentfulPages;

  return (
    <>
      <SeoDetails seoContent={seoContent} />
      <HeroImage seoContent={seoContent} />
      <Gallery images={contentSections} />
    </>
  );
};

export const query = graphql`
  query {
    contentfulPages(id: { eq: "9aa92d1c-8d7a-59d1-b2b1-ead6f7b1e634" }) {
      id
      title
      seoContent {
        pageTitle
        menuTitle
        slug
        description {
          description
        }
        featuredImage {
          title
          fluid(maxWidth: 2000) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
      contentSections {
        id
        title
        imageGallery {
          id
          title
          fluid(maxWidth: 2000) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
  }
`;

export default GalleryPage;
