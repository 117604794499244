import styled from 'styled-components';

const Inner = styled.div`
  max-width: 1160px;
  width: 100%;
  padding: 0 32px;
  margin: 0 auto;
`;

export default Inner;
