import styled from 'styled-components';

const Wrapper = styled.section`
  position: relative;
  z-index: 15;
  max-width: 100%;
  margin-top: 80px;
  margin-bottom: 80px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 32px;
  box-sizing: content-box;
  &::after {
    clear: both;
    content: '';
    display: table;
  }
  * {
    box-sizing: border-box;
  }
`;

export default Wrapper;
