import React from 'react';
import ReactModal from 'react-modal';

// Components
import { Slider } from '.';

// Context
import ContextConsumer from '../../../contextProvider';

const ModalStyles = {
  overlay: {
    position: `fixed`,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    minHeight: `100%`,
    minWidth: `100%`,
    overflowY: `auto`,
    zIndex: `999`,
    background: `#ffffff`
  },
  content: {
    background: `transparent`,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    padding: 0,
    border: 0,
    borderRadius: 0
  }
};

ReactModal.setAppElement('#___gatsby');

const Modal = ({ images }) => (
  <ContextConsumer>
    {({ showModal, toggleModalClose, imageindex, galleryid }) => (
      <ReactModal
        isOpen={showModal}
        contentLabel="Image Gallery"
        onRequestClose={toggleModalClose}
        shouldCloseOnOverlayClick
        style={ModalStyles}
      >
        <Slider
          images={images}
          imageindex={imageindex}
          galleryid={galleryid}
          toggleModalClose={toggleModalClose}
        />
      </ReactModal>
    )}
  </ContextConsumer>
);

export default Modal;
