import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import Swiper from 'react-id-swiper/lib/ReactIdSwiper.full';
import { Navigation, Controller } from 'swiper/dist/js/swiper.esm';

const RoomTitle = styled.h3`
  position: absolute;
  top: 0;
  left: 15px;
  z-index: 999998;
  letter-spacing: 3px;
  text-transform: uppercase;
  font-size: 15px;
  line-height: 45px;
  font-family: ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.text.light};
  @media screen and (max-width: ${(props) => props.theme.responsive.medium}) {
    top: 80px;
    left: 0;
    width: 100%;
    line-height: 1.5;
    text-align: center;
    z-index: 9;
    color: ${(props) => props.theme.colors.text.dark};
  }
`;

const ModalHeader = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 45px;
  z-index: 10001;
  display: flex;
  justify-content: flex-end;
  border-bottom: 1px solid rgba(0, 0, 0, 0.07);
  background: ${(props) => props.theme.colors.background.dark};
`;

const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid rgba(0, 0, 0, 0.07);
  height: 43px;
  width: 45px;
  cursor: pointer;
  transition: all 300ms ease-in-out;
  &:hover {
    img {
      &.close-icon {
        transform: rotate(180deg);
      }
    }
  }
  img {
    width: 20px;
    height: 20px;
    transition: all 300ms ease-in-out;
  }
`;

const SliderWrapper = styled.div`
  bottom: 0;
  height: 100%;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 5;
  max-height: 500px;
  @media screen and (min-width: ${(props) => props.theme.responsive.medium}) {
    top: 80px;
    height: calc(100% - 160px);
  }
  @media screen and (max-width: ${(props) => props.theme.responsive.medium}) {
    top: 50%;
    transform: translateY(-50%);
    height: calc(100% - 40%);
  }
  .swiper-container {
    &:first-child {
      position: relative;
      line-height: 0;
      height: 100%;
      overflow: visible;
      .swiper-wrapper {
        .swiper-slide {
          .gatsby-image-wrapper {
            height: 100%;
            width: 100%;
            @media screen and (min-width: ${(props) =>
                props.theme.responsive.medium}) {
              width: calc(100% - 200px);
              max-width: 800px;
              margin: 0 auto;
            }
          }
        }
      }
    }
    &:last-child {
      margin: 10px auto;
      max-width: 800px;
      .swiper-wrapper {
        .swiper-slide {
          width: 50px !important;
          height: 50px;
          border-radius: 3px;
          opacity: 0.4;
          cursor: pointer;
          transition: all 300ms ease-in-out;
          &:hover {
            opacity: 1;
            &.gatsby-image-wrapper {
              img {
                transform: scale(1.1);
              }
            }
          }
          &.swiper-slide-active {
            opacity: 1;
          }
          &.gatsby-image-wrapper {
            img {
              transition: all 1s cubic-bezier(0.59, 0, 0.06, 1) 0s !important;
            }
          }
        }
      }
    }
  }
`;

const NavButton = styled.div`
  top: 50%;
  transform: translateY(-50%);
  display: none;
  padding: 0;
  width: 44px !important;
  height: 44px !important;
  background-image: none !important;
  text-align: center;
  margin: 0 8px;
  transition: all 300ms ease-in-out;
  cursor: pointer;
  outline: none;
  position: absolute;
  z-index: 4;
  @media screen and (min-width: ${(props) => props.theme.responsive.medium}) {
    display: block;
    margin: 0 40px;
  }
  &:hover {
    opacity: 0.5;
  }
  &.swiper-button-next {
    right: 0;
  }
  &.swiper-button-previous {
    left: 0;
  }
  span {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    img {
      width: 24px;
      height: 24px;
    }
  }
`;

const Slider = ({ images, imageindex, galleryid, toggleModalClose }) => {
  const [gallerySwiper, updateGallerySwiper] = useState(null);
  const [thumbnailSwiper, updateThumbnailSwiper] = useState(null);

  useEffect(() => {
    if (gallerySwiper !== null && thumbnailSwiper !== null) {
      gallerySwiper.controller.control = thumbnailSwiper;
      thumbnailSwiper.controller.control = gallerySwiper;
    }
  }, [gallerySwiper, thumbnailSwiper]);

  const filterGallery = images.filter((i) => i.title === galleryid);

  const gallerySwiperParams = {
    modules: [Navigation, Controller],
    spaceBetween: 10,
    effect: 'fade',
    initialSlide: parseFloat(imageindex),
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    renderPrevButton: () => (
      <NavButton className="swiper-button-prev">
        <span>
          <img
            src="data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMS4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDQ3Ny4xNzUgNDc3LjE3NSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNDc3LjE3NSA0NzcuMTc1OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgd2lkdGg9IjUxMnB4IiBoZWlnaHQ9IjUxMnB4Ij4KPGc+Cgk8cGF0aCBkPSJNMTQ1LjE4OCwyMzguNTc1bDIxNS41LTIxNS41YzUuMy01LjMsNS4zLTEzLjgsMC0xOS4xcy0xMy44LTUuMy0xOS4xLDBsLTIyNS4xLDIyNS4xYy01LjMsNS4zLTUuMywxMy44LDAsMTkuMWwyMjUuMSwyMjUgICBjMi42LDIuNiw2LjEsNCw5LjUsNHM2LjktMS4zLDkuNS00YzUuMy01LjMsNS4zLTEzLjgsMC0xOS4xTDE0NS4xODgsMjM4LjU3NXoiIGZpbGw9IiMwMDAwMDAiLz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8L3N2Zz4K"
            alt="Previous"
          />
        </span>
      </NavButton>
    ),
    renderNextButton: () => (
      <NavButton className="swiper-button-next">
        <span>
          <img
            src="data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxMjkgMTI5IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAxMjkgMTI5IiB3aWR0aD0iNTEycHgiIGhlaWdodD0iNTEycHgiPgogIDxnPgogICAgPHBhdGggZD0ibTQwLjQsMTIxLjNjLTAuOCwwLjgtMS44LDEuMi0yLjksMS4ycy0yLjEtMC40LTIuOS0xLjJjLTEuNi0xLjYtMS42LTQuMiAwLTUuOGw1MS01MS01MS01MWMtMS42LTEuNi0xLjYtNC4yIDAtNS44IDEuNi0xLjYgNC4yLTEuNiA1LjgsMGw1My45LDUzLjljMS42LDEuNiAxLjYsNC4yIDAsNS44bC01My45LDUzLjl6IiBmaWxsPSIjMDAwMDAwIi8+CiAgPC9nPgo8L3N2Zz4K"
            alt="Next"
          />
        </span>
      </NavButton>
    ),
    getSwiper: updateGallerySwiper
  };
  const thumbnailSwiperParams = {
    modules: [Controller],
    spaceBetween: 10,
    centeredSlides: true,
    initialSlide: parseFloat(imageindex),
    slidesPerView: 'auto',
    touchRatio: 0.2,
    slideToClickedSlide: true,
    getSwiper: updateThumbnailSwiper
  };

  return (
    <>
      <RoomTitle>{galleryid} Gallery</RoomTitle>
      <ModalHeader>
        <Button onClick={toggleModalClose}>
          <img
            src="data:image/svg+xml;base64,
            PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAyMS45IDIxLjkiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDIxLjkgMjEuOSIgd2lkdGg9IjUxMiIgaGVpZ2h0PSI1MTIiIGNsYXNzPSIiPjxnPjxwYXRoIGQ9Ik0xNC4xLDExLjNjLTAuMi0wLjItMC4yLTAuNSwwLTAuN2w3LjUtNy41YzAuMi0wLjIsMC4zLTAuNSwwLjMtMC43cy0wLjEtMC41LTAuMy0wLjdsLTEuNC0xLjRDMjAsMC4xLDE5LjcsMCwxOS41LDAgIGMtMC4zLDAtMC41LDAuMS0wLjcsMC4zbC03LjUsNy41Yy0wLjIsMC4yLTAuNSwwLjItMC43LDBMMy4xLDAuM0MyLjksMC4xLDIuNiwwLDIuNCwwUzEuOSwwLjEsMS43LDAuM0wwLjMsMS43QzAuMSwxLjksMCwyLjIsMCwyLjQgIHMwLjEsMC41LDAuMywwLjdsNy41LDcuNWMwLjIsMC4yLDAuMiwwLjUsMCwwLjdsLTcuNSw3LjVDMC4xLDE5LDAsMTkuMywwLDE5LjVzMC4xLDAuNSwwLjMsMC43bDEuNCwxLjRjMC4yLDAuMiwwLjUsMC4zLDAuNywwLjMgIHMwLjUtMC4xLDAuNy0wLjNsNy41LTcuNWMwLjItMC4yLDAuNS0wLjIsMC43LDBsNy41LDcuNWMwLjIsMC4yLDAuNSwwLjMsMC43LDAuM3MwLjUtMC4xLDAuNy0wLjNsMS40LTEuNGMwLjItMC4yLDAuMy0wLjUsMC4zLTAuNyAgcy0wLjEtMC41LTAuMy0wLjdMMTQuMSwxMS4zeiIgZGF0YS1vcmlnaW5hbD0iIzAwMDAwMCIgY2xhc3M9ImFjdGl2ZS1wYXRoIiBzdHlsZT0iZmlsbDojRkZGRUZFIiBkYXRhLW9sZF9jb2xvcj0iI0Y2RjVGNSI+PC9wYXRoPjwvZz4gPC9zdmc+"
            alt="Close gallery"
            className="close-icon"
          />
        </Button>
      </ModalHeader>
      <SliderWrapper>
        <Swiper {...gallerySwiperParams}>
          {filterGallery[0].imageGallery.map((i) => (
            <div key={i.id}>
              <Img fluid={i.fluid} alt={i.description} title={i.description} />
            </div>
          ))}
        </Swiper>
        <Swiper {...thumbnailSwiperParams}>
          {filterGallery[0].imageGallery.map((i) => (
            <Img
              key={i.id}
              fluid={i.fluid}
              alt={i.description}
              title={i.description}
            />
          ))}
        </Swiper>
      </SliderWrapper>
    </>
  );
};

export default Slider;
