import React from 'react';
import Img from 'gatsby-image';
import styled from 'styled-components';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

// Context
import ContextConsumer from '../../../contextProvider';

const TabTop = styled(TabList)`
  && {
    text-align: center;
    margin-bottom: 3rem;
  }
`;

const TabSelect = styled(Tab)`
  && {
    position: relative;
    display: inline-block;
    margin: 0 1rem;
    cursor: pointer;
    font: 16px/19px ${props => props.theme.fonts.body};
    font-weight: 700;
    text-transform: uppercase;
    color: ${props => props.theme.colors.text.normal};
    padding-bottom: 4px;
    &:after {
      content: '';
      height: 1px;
      width: 0;
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: ${props => props.theme.colors.text.dark};
      transition: all 300ms ease-in-out;
    }
    &:hover {
      &:after {
        width: 100%;
      }
    }
    &.react-tabs__tab--selected {
      color: ${props => props.theme.colors.secondary};
      &:after {
        width: 100%;
      }
      &:hover {
        &:after {
          width: 0;
        }
      }
    }
  }
`;

const TabContent = styled(TabPanel)`
  && {
    > div {
      display: inline-block;
      border: 0;
      padding: 0;
      outline: none;
      padding-right: 2vw;
      margin-bottom: 2vw;
      @media screen and (min-width: ${props => props.theme.responsive.small}) {
        width: 33.33333%;
      }
      @media screen and (max-width: ${props => props.theme.responsive.small}) {
        width: 100%;
      }
    }
  }
`;

const TabImage = styled(Img)`
  && {
    cursor: pointer;
    height: 325px;
    transition: all 300ms ease-in-out;
    @media screen and (max-width: ${props => props.theme.responsive.small}) {
      height: 300px;
    }
    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: 0;
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgNDIgNDIiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDQyIDQyOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgd2lkdGg9IjUxMiIgaGVpZ2h0PSI1MTIiIGNsYXNzPSIiPjxnPjxwb2x5Z29uIHBvaW50cz0iNDIsMjAgMjIsMjAgMjIsMCAyMCwwIDIwLDIwIDAsMjAgMCwyMiAyMCwyMiAyMCw0MiAyMiw0MiAyMiwyMiA0MiwyMiAiIGRhdGEtb3JpZ2luYWw9IiMwMDAwMDAiIGNsYXNzPSJhY3RpdmUtcGF0aCIgc3R5bGU9ImZpbGw6I0ZGRkZGRiIgZGF0YS1vbGRfY29sb3I9IiNmZmZmZmYiPjwvcG9seWdvbj48L2c+IDwvc3ZnPg==');
      background-position: center;
      background-repeat: no-repeat;
      background-size: 30% auto;
      background-color: ${props => props.theme.colors.primary};
      transition: all 300ms ease-in-out;
    }
    img {
      display: block;
      width: 100%;
    }
    &:hover {
      transform: scale(0.95);
      &:after {
        opacity: 0.8;
      }
    }
  }
`;

const Galleries = ({ images }) => (
  <ContextConsumer>
    {({ toggleModalOpen }) => (
      <Tabs>
        <TabTop>
          {images.map(i => (
            <TabSelect key={i.id}>{i.title}</TabSelect>
          ))}
        </TabTop>
        {images.map(i => (
          <TabContent key={i.id}>
            {i.imageGallery.map((j, index) => (
              <div
                key={j.id}
                role="button"
                data-galleryid={i.title}
                data-imageid={index}
                onClick={toggleModalOpen}
              >
                <TabImage fluid={j.fluid} alt={j.description} />
              </div>
            ))}
          </TabContent>
        ))}
      </Tabs>
    )}
  </ContextConsumer>
);

export default Galleries;
