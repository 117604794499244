import React from 'react';

// Components
import { Wrapper, Inner, Galleries, Modal } from './files';

const Gallery = ({ images }) => (
  <Wrapper>
    <Inner>
      <Galleries images={images} />
      <Modal images={images} />
    </Inner>
  </Wrapper>
);

export default Gallery;
